<template lang="pug">
  li.CardDate.bg-white.rounded-xl.shadow-bx-small-sm.p-3.my-5
    .flex
      .w-16.h-16.bg-primary.rounded-xl(v-if="!date.src")
      img(:data-src="date.src" :alt="date.place" ref="imgRef" loading="lazy" v-else).w-16.h-16.bg-primary.rounded-xl.lazy-loading
      article.ml-3.w-full.maxarticle
        .flex.justify-between.items-center
          h4.text-primary.font-semibold.text-sm.truncate.w-full.max-w-full {{ branch.name }}
          p(:class="date.confirmation ? 'text-good_color' : 'text-info_color'").text-xs.whitespace-nowrap
            | {{ date.status == 'CONFIRMED' ? "Confirmado" : "Por confirmar" }}
        p.w-full.max-w-full.text-sm {{ branch.address || "No hay dirección" }}
        p.truncate.w-full.max-w-full.text-sm {{ branch.phone ? "Tel. " + branch.phone : "No hay telefono" }}
    hr.opacity-20.mt-2.mb-1
    article.m-0
      .flex
        p: small.mx-1.text-secondary.font-semibold {{ date_start }}
        p: small.mx-1.text-info_color.font-semibold {{ hour }}
      .flex
        p(v-if="typeof date.reason =='object'"): small.mx-1.text-secondary.font-semibold(v-for="(numb, i) in date.reason.formulas.split(';')" :key="i") 
          label(v-if="numb != 9999999999999") {{numb}}
          label(v-else) No se especifico una formula
        p(v-else): small.mx-1.text-secondary.font-semibold {{date.reason}}
    .w-full.flex.justify-end
      .maxwidthbutton(v-if="date.reason")
        button-component(
          text="Gestionar"
          themeSelected="primaryOther"
          size="text-xs p-2 rounded-bd-small"
          :onClick="manageDate"
        )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
var moment = require("moment");

export default {
  name: "CardDateResumen",
  components: {
    ButtonComponent: () => import("./general/Button.vue"),
  },
  props: {
    date: Object,
  },
  mounted() {
    if ("loading" in HTMLImageElement.prototype) {
      if (this.$refs.imgRef) {
        this.$refs.imgRef.src = this.$refs.imgRef.dataset.src;
      }
    }
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
    ]),
    ...mapMutations("drugstore", [
      "setState",
    ]),
    manageDate() {
      this.setState({ key: "manageDate", value: {
        name: this.branch.name,
        startDate: this.date.startAt,
        label: moment(this.date.startAt).format('MMMM Do YYYY'), // "Viernes 12 de Septiembre",
        hora: moment(this.date.startAt).format('LT'),
        phone: this.branch.phone,
        address: this.branch.address,
        code: this.date.code,
        branch: this.date?.branch?.id,
        cost_center: this.date?.reason?.point,
        reason: this.date?.reason,
        schedule: this.date.schedule.id
      }, });
      this.changeTrip("ManageDate");
    },
  },
  computed: {
    ...mapState({
      points: (state) => state.environment.environment.points,
    }),
    date_start(){
      return moment(this.date.startAt).format("MMMM D YYYY")
    },
    hour(){
      return moment(this.date.startAt).format("h:mm A")
    },
    branch() {
      return this.date.reason ? this.points.find(p=> {
        if(typeof this.date.reason == 'number'){
          return p.branch_id == this.date.branch.id
        }else{
          return parseInt(p.cost_center) == parseInt(this.date.reason.point)
        }
      }) : this.date;
    }
  }
}
</script>

<style scoped>
  .small-p {
    margin-top: -7px;
  }
  .maxwidthbutton {
    max-width: 85px;
    margin-top: -5px;
  }
  .maxarticle {
    max-width: 80%;
  }
  @media screen and (max-width: 400px) {
    .maxarticle {
      max-width: 74%;
    }
  }
</style>